export default [
    {
        "path" : "\/pips\/:id",
        "name" : "changemanagement_pips_single",
        "meta" : {
            "show" : false,
            "label" : {"de" : "PIP", "en" : "PIP"},
            "requiresAuthentication" : true,            
            "gridLayout" : [
                {
                    "x": 2,
                    "y": 0,
                    "w": 8,
                    "h": 6,
                    "minW": 7,
                    "minH" : 4,
                    "i": "pipSS",
                    "static": false,
                    "module": "changemanagement",
                    "widget": "SingleSolver",
                    "customProperties": {
                    }           
                },
                {
                    "x": 0,
                    "y": 0,
                    "w": 2,
                    "h": 6,
                    "minW": 2,
                    "minH" : 4,
                    "i": "pipTS",
                    "static": false,
                    "module": "changemanagement",
                    "widget": "TableSolver",
                    "customProperties": {
                        "card": true,
                        "iriAlias" : "/pips/",
                        "filter": {
                            "categories" : ["pip"]
                        }
                    }           
                }
            ]
        }
    },
    {
        "path" : "\/pips",
        "name" : "changemanagement_pips",
        "meta" : {
            "show" : {"sidebar" : true, "group" : "inl" },
            "label" : {"de" : "PIP", "en" : "PIP"},
            "requiresAuthentication" : true,
            "suffixComponent" : {
                "widget": "notificationDisplay",
                "customProperties": {
                    "dataSources": [
                        {"module": "changemanagement", "context": {"iri": "cm_solvers", "solver" : "pip"} }
                ]
                }
            },
            "gridLayout" : [
                
                {
                    "x": 0,
                    "y": 0,
                    "w": 10,
                    "h": 6,
                    "i": "pipTS",
                    "static": false,
                    "module" : "changemanagement",
                    "widget" : "TableSolver",
                    "customProperties": {
                        "iriAlias" : "/pips/",
                        "filter" : {
                            "categories" : ["pip"]
                        }
                    },
                    "props" : {
                        "fullHeight" : true
                    }
                }
            ]
        }
    },
    {
        "path" : "\/pqms\/:id",
        "name" : "changemanagement_pqm_single",
        "meta" : {
            "show" : false,
            "label" : {"de" : "PQM", "en" : "PQM"},
            "requiresAuthentication" : true,            
            "gridLayout" : [
                {
                    "x": 2,
                    "y": 0,
                    "w": 8,
                    "h": 6,
                    "minW": 7,
                    "minH" : 4,
                    "i": "cmSS",
                    "static": false,
                    "module": "changemanagement",
                    "widget": "SingleSolver",
                    "customProperties": {
                    }           
                },
                {
                    "x": 0,
                    "y": 0,
                    "w": 2,
                    "h": 6,
                    "minW": 2,
                    "minH" : 4,
                    "i": "cmTS",
                    "static": false,
                    "module": "changemanagement",
                    "widget": "TableSolver",
                    "customProperties": {
                        "iriAlias" : "/pqms/",
                        "card": true,
                        "filter": {
                            "categories" : ['customer_complaint']
                        }
                    }           
                }
            ]
        }
    },
    {
        "path" : "\/pqms",
        "name" : "changemanagement_pqms",
        "meta" : {
            "show" : {"sidebar" : true, "group" : "qual" },
            "label" : {"de" : "PQM", "en" : "PQM"},
            "requiresAuthentication" : true,
            "breadcrumbs": [
                {"text": {"de": "Start", "en": "Home"}, "to": "/"},
                {"text": {"de": "PQM", "en": "PQM"},"active": true}
            ],
            "suffixComponent" : {
                "widget": "notificationDisplay",
                "customProperties": {
                    "dataSources": [
                        {"module": "changemanagement", "context": {"iri": "cm_solvers", "solver" : "pqm"} }
                ]
                }
            },
            "gridLayout" : [
                
                {
                    "x": 0,
                    "y": 0,
                    "w": 10,
                    "h": 6,
                    "i": "cmTS",
                    "static": false,
                    "module" : "changemanagement",
                    "widget" : "TableSolver",
                    "customProperties": {
                        "iriAlias" : "/pqms/",
                        "filter" : {
                            "categories" : ['customer_complaint']
                        }
                    },
                    "props" : {
                        "fullHeight" : true
                    }
                }
            ]
        }
    },
    {
        "path" : "\/cm_solvers\/:id",
        "name" : "changemanagement_solving_single",
        "meta" : {
            "show" : false,
            "label" : {"de" : "Problem", "en" : "Problem"},
            "requiresAuthentication" : true,            
            "gridLayout" : [
                {
                    "x": 2,
                    "y": 0,
                    "w": 8,
                    "h": 6,
                    "minW": 7,
                    "minH" : 4,
                    "i": "cmSS",
                    "static": false,
                    "module": "changemanagement",
                    "widget": "SingleSolver",
                    "customProperties": {
                    }           
                },
                {
                    "x": 0,
                    "y": 0,
                    "w": 2,
                    "h": 6,
                    "minW": 2,
                    "minH" : 4,
                    "i": "solversTS",
                    "static": false,
                    "module": "changemanagement",
                    "widget": "TableSolver",
                    "customProperties": {
                        "card": true,
                        "filter": {
                        }
                    }           
                }
            ]
        }
    },
    {
        "path" : "\/cm_solvers",
        "name" : "changemanagement_solving",
        "meta" : {
            "show" : {"sidebar" : true, "group" : "project" },
            "label" : {"de" : "Problemlösung", "en" : "Problem solving"},
            "requiresAuthentication" : true,
            "breadcrumbs": [
                {"text": {"de": "Start", "en": "Home"}, "to": "/"},
                {"text": {"de": "PQM", "en": "PQM"},"active": true}
            ],
            "suffixComponent" : {
                "widget": "notificationDisplay",
                "customProperties": {
                    "dataSources": [
                        {"module": "changemanagement", "context": {"iri": "cm_solvers"} }
                ]
                }
            },
            "gridLayout" : [
                
                {
                    "x": 0,
                    "y": 0,
                    "w": 10,
                    "h": 6,
                    "i": "solversTS",
                    "static": false,
                    "module" : "changemanagement",
                    "widget" : "TableSolver",
                    "customProperties": {
                        "filter" : {
                        }
                    },
                    "props" : {
                        "fullHeight" : true
                    }
                }
            ]
        }
    },
    {
        "path" : "\/cm_permanents\/:id",
        "name" : "changemanagement_permanent_single",
        "meta" : {
            "show" : false,
            "label" : {"de" : "ECR", "en" : "ECR"},
            "requiresAuthentication" : true,
            "breadcrumbs": [
                {"text": {"de": "Start", "en": "Home"}, "to": "/"},
                {"text": {"de": "Änderungsanträge", "en": "Changerequests"}, "to": "/cm_permanents"},
                {"text": "@iri", "active": true}
            ],
            "gridLayout" : [
                {
                    "x": 2,
                    "y": 0,
                    "w": 8,
                    "h": 6,
                    "minW": 7,
                    "minH" : 4,
                    "i": "cmSP",
                    "static": false,
                    "module": "changemanagement",
                    "widget": "SinglePermanent",
                    "customProperties": {
                    }           
                },
                {
                    "x": 0,
                    "y": 0,
                    "w": 2,
                    "h": 6,
                    "minW": 2,
                    "minH" : 4,
                    "i": "cmTP",
                    "static": false,
                    "module": "changemanagement",
                    "widget": "TablePermanent",
                    "customProperties": {
                        "card": true,
                        "filter": {
                        }
                    },
                    "props" : {
                        "fullHeight" : true
                    }           
                }
            ]
        }
    },
    {
        "path" : "\/cm_permanents",
        "name" : "changemanagement_permanent",
        "meta" : {
            "show" : {"sidebar" : true, "group" : "project" },
            "label" : {"de" : "ECR", "en" : "ECR"},
            "requiresAuthentication" : true,
            "breadcrumbs": [
                {"text": {"de": "Start", "en": "Home"}, "to": "/"},
                {"text": {"de": "Änderungsanträge", "en": "Changerequests"}, "active": true}
            ],
            "suffixComponent" : {
                "widget": "notificationDisplay",
                "customProperties": {
                    "dataSources": [
                        {"module": "changemanagement", "context": {"iri": "cm_permanents"} }
                ]
                }
            },
            "gridLayout" : [
                
                {
                    "x": 0,
                    "y": 0,
                    "w": 10,
                    "h": 6,
                    "i": "cmTP",
                    "static": false,
                    "module" : "changemanagement",
                    "widget" : "TablePermanent",
                    "customProperties": {
                    },
                    "props" : {
                        "fullHeight" : true
                    }
                }
            ]
        }
    },
    {
        "path" : "\/cm_containments\/:id",
        "name" : "changemanagement_containment_single",
        "meta" : {
            "show" : false,
            "label" : {"de" : "RW", "en" : "RW"},
            "requiresAuthentication" : true,
            "breadcrumbs": [
                {"text": {"de": "Start", "en": "Home"}, "to": "/"},
                {"text": {"de": "Nacharbeiten", "en": "Reworks"}, "to": "/cm_containments"},
                {"text": "@iri", "active": true}
            ],
            "gridLayout" : [
                {
                    "x": 2,
                    "y": 0,
                    "w": 8,
                    "h": 6,
                    "minW": 7,
                    "minH" : 4,
                    "i": "cmCS",
                    "static": false,
                    "module": "changemanagement",
                    "widget": "SingleContainment",
                    "customProperties": {
                    }           
                },
                {
                    "x": 0,
                    "y": 0,
                    "w": 2,
                    "h": 6,
                    "minW": 2,
                    "minH" : 4,
                    "i": "cmTC",
                    "static": false,
                    "module": "changemanagement",
                    "widget": "TableContainment",
                    "customProperties": {
                        "card": true,
                        "filter": {
                        }
                    },
                    "props" : {
                        "fullHeight" : true
                    }           
                }
            ]
        }
    },
    {
        "path" : "\/cm_containments",
        "name" : "changemanagement_containments",
        "meta" : {
            "show" : {"sidebar" : true, "group": "project" },
            "label" : {"de" : "Nacharbeiten", "en" : "Reworks"},
            "requiresAuthentication" : true,
            "breadcrumbs": [
                {"text": {"de": "Start", "en": "Home"}, "to": "/"},
                {"text": {"de": "Nacharbeiten", "en": "Reworks"}, "active": true}
            ],
            "suffixComponent" : {
                "widget": "notificationDisplay",
                "customProperties": {
                    "dataSources": [
                        {"module": "changemanagement", "context": {"iri": "cm_containments"} }
                ]
                }
            },
            "gridLayout" : [
                
                {
                    "x": 0,
                    "y": 0,
                    "w": 10,
                    "h": 6,
                    "i": "cmTC",
                    "static": false,
                    "module" : "changemanagement",
                    "widget" : "TableContainment",
                    "customProperties": {
                    },
                    "props" : {
                        "fullHeight" : true
                    }
                }
            ]
        }
    },
    {
        "path" : "\/claims",
        "name" : "changemanagement_claims",
        "meta" : {
            "show" : {"sidebar" : true, "group" : "qual" },
            "label" : {"de" : "Reklamationen", "en" : "Claims"},
            "requiresAuthentication" : true,
            "gridLayout" : [                
                {
                    "x": 0,
                    "y": 0,
                    "w": 10,
                    "h": 6,
                    "i": "claimsTS",
                    "static": false,
                    "module" : "changemanagement",
                    "widget" : "TableClaim",
                    "customProperties": {
                        "filter" : {
                        }
                    },
                    "props" : {
                        "fullHeight" : true
                    }
                }
            ],
            "allowAccess": function() {
                    let userRoles = window?.globalVue?.$store.getters.getCurrentUserRoles ?? []
                    //return (userRoles.includes('ATERA_USER')) ?? false
                    return false
                }
        }
    },
]