<template>
<div class="dynamic--loader mh-100 mw-100 " :class="{'overflow-auto' : blockOverflow && !overflow && !neverOverflow && !isOverflowForbidden(), 'overflow-hidden': !overflow && (neverOverflow || isOverflowForbidden()), 'h-100' : fullHeight }">
  <div v-if="loadingScript && !noLoadingIcon"><b-spinner v-show="showLoading"></b-spinner></div>
  <div v-else-if="!moduleLoaded && showLoading && !noLoadingIcon" class="no-content--box d-flex align-items-center justify-content-center text-left w-100 h-100">
	<b-iconstack font-scale="5" class="w-75 h-75">
      <b-icon stacked icon="cloud" scale="0.8" shift-h="-0.25" ></b-icon>
      <b-icon stacked icon="circle-slash" rotate="90" variant="info"></b-icon>
    </b-iconstack>  
  </div>
  <component 
  		@input="$emit('input',$event)" 
	  	@requireOverflow="removeOverflowClass" 
		class="content--box h-100 " 
		:class="compClass" 
		:style="width ? 'width:'+(!isNaN(parseInt(width)) ? (width+'px') : width )+';white-space: nowrap;' : ''"
		v-else 
		:id="mountID" 
		:is="componentName" 
		v-bind="{...$attrs, ...customProperties}" 
		v-on="$listeners"
		:disabled="disabled"
		:value="value || (customProperties && customProperties.value) || ''" >
		<template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>
  </component>
  </div>
</template>

<script>

import Vue from 'vue'
import { libraryManager } from '@/helper/LibraryManager'

import { ownerManager } from '@/helper/OwnerManager'

	
export default {
  name: 'DynamicLoader',
  inheritAttrs: false,
  data() { return {
	loadingScript: true,
	mountID: this.$id('dynamic-mount'),
	blockOverflow: true,
	moduleLoaded: false,
	curModule: "",
	//componentName: ""
  }},
  props: {
	unauthenticated: { type: Boolean, default: false },
	noLoadingIcon: {type: Boolean, default: false },
	widget: String,
	width: { type: [Number,String], default: 0},
	module: String,
	customProperties: Object,
	compClass: String,
	overflow: Boolean,
	neverOverflow: {type: Boolean, default: false},
	disabled: { type: Boolean, default: false},
	value: null, //any type accepted,
	fullHeight: { type: Boolean, default: false}
  },
  watch: {
	  "$store.getters.isAuthenticated": {
		immediate: true,  
		handler: function() {
		  if(!this.authenticatedOnly || this.$store.getters.isAuthenticated) {
			  this.createAsyncContent();
		  }
		}
	  },
	  "module" : {
		  immediate: true,
		  handler: function() { this.createAsyncContent() }
	  },
	  "widget" : {
		  immediate: true,
		  handler: function() { this.createAsyncContent() }
	  },
	  "value" : {
		  immediate: true,
		  handler: function() { this.createAsyncContent() }
	  }
  },
  computed: {
	showLoading: (vm) => !vm.unauthenticated ? vm.$store.getters.isAuthenticated : vm.loadingScript,
	
	loadingFailed : (vm) => {
		return !vm.componentName && !vm.loadingScript
	},
	useModule: function(vm) {
		let useModule = vm.module
			if(useModule === "" || !useModule) {
				let iriModule = vm.$store.getters.getModuleFromIri(vm.value)
				useModule = iriModule && iriModule.module || null
			}
		return useModule
		},
	componentName: function(vm) {
		//only widget and no module given
		//fetch module from value
		
		//check if widget is a base widget
		if(this.$options.components[vm.widget]) {
			return vm.widget
		}
		if(vm.useModule) {
			if(vm.moduleLoaded) {
				return window[vm.useModule +'-'+vm.widget] || null
			}
		} else {
			return null
		}

		return null
	}
  },
  provide() { return {
		'neverOverflow' : this.isOverflowForbidden
	}
  },
  inject: {
	  'checkOverflowStatus' : { from: 'neverOverflow', default: () => () => false }
  },
  methods: {
	isOverflowForbidden() {
		return this.checkOverflowStatus() || this.neverOverflow;
	},
	removeOverflowClass() {
		this.blockOverflow = false;
	},
	createAsyncContent: async function() {
		//add library if useModule is set
		if(!!this.useModule) {
			this.moduleLoaded = false
			this.loadingScript = true
			try {
				await libraryManager.addLibrary(this.useModule)
				this.moduleLoaded = true
			} catch(e) {
				this.moduleLoaded = false
			} finally {
				this.loadingScript = false
			}
		}
		if(!!this.widget) {
			this.loadingScript = false
			this.moduleLoaded = true
		}
	},
  }
}
</script>

<style scoped lang="scss">
@import "../style/customvars";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import '../../node_modules/bootstrap-vue/src/index.scss';

.no-content--box {
		opacity: 0.1;
		background-color: $light;
	}
	
</style>
