<i18n locale="en" lang="yaml">
  searchlabel: "Show detail search"
  storeFilter: "Save filter"
  FilterName: "Filter name"
  itemsPerPage: "Items per Page"
  lbl.Start : "Start"
  miniMonitor: "Currently displayed items / Items after filtering / Items total"
  no.content: "No items"
  no.project: "No project selected"
  clearFilters: "Remove all filtering, sorting and grouping"
  open.details: "Detailled view"
  archive: "Show inactive elements (completed/cancelled/...)"
  tooltip.toDetails: "Switch to table view"
  tooltip.toTable: "Switch to detail view"
  tooltip.filter: "Configure table columns"
  image.success.message: "Screenshot in die Zwischenablage abgelegt"
  image.success.title: "Screenshot erstellt"
  box.search: "Suche"
  box.edit: "Bearbeiten"
  box.view: "Ansicht"
  project.gantts: "Project timetables"
  reload.successful: "Reload completed"
</i18n>

<i18n locale="de" lang="yaml">
  searchlabel: "Detailsuche"
  storeFilter: "Filter speichern"
  FilterName: "Filterbezeichnung"
  itemsPerPage: "Elemente pro Seite"
  lbl.Start : "Start"
  miniMonitor: "Anzahl nach Filterung / Aktuell gezeigte Elemente / Gesamtanzahl"
  no.content: "Keine Elemente"
  no.project: "Kein Projekt ausgewählt"
  clearFilters: "Entferne Filterung, Sortierung und Gruppierung"
  open.details: "Detailansicht"
  archive: "Zeige nur inaktive Elemente an (abgeschlossen,abgebrochen,...)"
  tooltip.toDetails: "In Tabellenansicht wechseln"
  tooltip.toTable: "In Detailansicht wechseln"
  tooltip.filter: "Tabellenspalten konfigurieren"
  image.success.message: "Screenshot added to clipboard"
  image.success.title: "Screenshot created"
  box.search: "Search"
  box.edit: "Edit"
  box.view: "View"
  project.gantts: "Projektterminpläne"
  reload.successful: "Neuladen abgeschlossen"
</i18n>

<template>
  <component :is="wrapInCollapseBox ? 'collapse-box' : 'div'" :id="$id('base-table-collapse')" bodyClass="p-0" class="h-100 overflow-hidden">
    <template #title><slot name="title">{{ title }}</slot></template>
    <template #actions>
      <div class="d-flex justify-content-center align-items-center">
        <b-button-group size="sm" variant="light" class="mx-1">
          <b-button class="p-1 border" variant="light" :disabled="page <= 1" @click="page = Math.max(page-1,1)"><b-icon-chevron-left /></b-button>
          <b-button class="p-1 border" variant="light" :disabled="page >= Math.ceil(sortedRowData.length / itemsPerPage)" @click="page = Math.min(Math.ceil(sortedRowData.length / itemsPerPage),page+1)"><b-icon-chevron-right /></b-button>
        </b-button-group>

      <slot name="collapse-exclusive"></slot>

      <b-input-group size="sm" class="ml-1 w-auto flex-nowrap" :title="$t('miniMonitor')" v-b-tooltip.window v-if="pagination">
          <b-input-group-prepend>
            <b-input-group-text>
              {{ filteredRowData.length == 0 ? 0 : (page - 1)*itemsPerPage + 1 }}-{{ Math.min(page*itemsPerPage,filteredRowData.length) }} /
              {{ filteredRowData.length }} / 
              {{ ownerRowData.length }}
            </b-input-group-text> 
          </b-input-group-prepend>
        </b-input-group>


        <slot name="buttons" 
          v-bind:columnData="allColumns"
          v-bind:rowData="preppedRowData"
          v-bind:selectedIris="selectedIris"
          v-bind:slotData="slotData"></slot>

      <b-icon-three-dots-vertical class="ml-1 mr-1" v-b-toggle="$id('menu')" v-show="allowToolbarToggle && !fixed" />
      </div>
    </template>
  <div class="base-table--wrapper mh-100 h-100 d-flex flex-column" ref="wrapper">
    <div v-if="!noTitle && !wrapInCollapseBox" class="w-100 base-table--title-bar h4 titlebar p-2 mb-0 d-flex">
      
       <slot name="title">{{ title }}</slot>
        
        

        <b-button @click="forceReloadData()" variant="none" class="ml-auto" style="right: 10px;">
          <b-icon-arrow-repeat :animation="isReloading ? 'spin' : ''" variant="primary" />
        </b-button>
    </div>
    <b-collapse :id="$id('menu')" :visible="allowToolbarToggle && !wrapInCollapseBox">
    <aside class="p-0 mx-0 pb-2 table--menu d-flex flex-row align-items-center" ref="menu" v-if="!noHeader">
      <!-- @slot slot for action blocks prepending the actual blocks
          @binding {Array} columnData the full column configuration array
          @binding {Array} rowData the currently displayed rows in table configuration
          @binding {any} slotData properties passed via the slotData property

      -->
      <slot name="prepend-blocks" 
            v-bind:columnData="allColumns"
            v-bind:rowData="preppedRowData"
            v-bind:slotData="slotData"></slot>
      <!--EDITBLOCK -->
      <div class="d-flex flex-column px-2 border-right border-secondary">
        <div class="small text-muted text-center w-100" style="font-size: 10px;">{{ $t('box.edit') }}</div>
        <div class="d-flex flex-row flex-grow-1 align-items-center">
          <b-button-group size="sm" class="mr-1">   
            <!--  @slot slot for action buttons in the edit block (prepended)
                  @binding {Array} columnData the full column configuration array
                  @binding {Array} rowData the currently displayed rows in table configuration
                  @binding {any} slotData properties passed via the slotData property

              -->
            <slot name="buttons" 
              v-bind:columnData="allColumns"
              v-bind:rowData="preppedRowData"
              v-bind:selectedIris="selectedIris"
              v-bind:slotData="slotData"></slot>
          </b-button-group> 


        <b-button v-if="!noScreenshot" @click="renderScreenshot('mainTable')" variant="light" >
          <b-iconstack font-size="0.8">
            <b-icon-camera-fill variant="primary" />
            <b-icon-star-fill animation="throb" variant="light" v-show="renderingScreenshot" />
          </b-iconstack>    
        </b-button>

        <base-exporter
        v-if="allowExport"
          :selectedIris="selectedIris"
          :selectedRows="selectedRowItems"
          :displayedColumns="selectedShownColumns"
        />

        <mail-sender :items="selectedIris" />

        <batch-processor 
          v-if="allowBatchProcessing"
          :value="selectedIris" 
          :batchPreProcessor="batchPreProcessor"
          :fields="allColumns" />
          
        <dynamic-loader v-if="allowBatchRelationProcessing" module="relation" widget="BatchRelationProcessing" :value="selectedIris" />

        </div>
      </div>
      <!--END EDITBLOCK -->


       <!--VIEWBLOCK -->
      <div class="d-flex flex-column px-2 border-right border-secondary">
        <div class="small text-muted text-center w-100" style="font-size: 10px;">{{ $t('box.view') }}</div>
        <div class="d-flex flex-row flex-grow-1 align-items-center">

            <!-- SHOW NAVIGATION BUTTON -->
            <b-button size="sm" class="px-1 py-0" variant="warning" :to="backTo" v-if="backTo != ''" v-b-tooltip.window :title="$t('tooltip.toDetails')">
              <b-icon-arrow-left variant="dark"/>
            </b-button>
            <b-button size="sm" class="px-1 py-0" variant="outline-warning" @click="switchToDetails()" v-if="!noSwitchToDetails" v-b-tooltip.window :title="$t('tooltip.toTable')">
              <b-icon-arrow-right variant="secondary" />
            </b-button>    


        <!-- SHOW PAGINATION BUTTON -->
        <b-pagination
            v-if="pagination"
            size="sm"
            v-model="page"
            :total-rows="sortedRowData.length"
            :per-page="itemsPerPage"
            class="mx-1 my-0"
          ></b-pagination>

        <b-input-group 
          v-b-tooltip.window
          :title="$t('itemsPerPage')"          
          size="sm" 
          class="mx-1 my-0 w-auto flex-nowrap flex-shrink-1" 
          v-if="pagination">
          <b-input-group-prepend>
            <b-input-group-text size="sm" class="py-0 px-1">
              <b-icon-files></b-icon-files>
            </b-input-group-text> 
          </b-input-group-prepend>
          <b-input type="number" size="sm" style="width:60px;" :step="25" min="25" v-model="itemsPerPage"></b-input> 
        </b-input-group>

        <b-dropdown 
          v-if="!card"
          boundary="window"
          class="my-0 mx-1 py-0"
          size="sm"  
          variant="link" 
          toggle-class="text-decoration-none"
          v-b-tooltip.window
          :title="$t('tooltip.filter')"             
          >
          <template #button-content>
            <b-icon-eye>
            <span class="sr-only">{{ $t('tooltip.filter') }}</span>
            </b-icon-eye>
          </template>
          <b-dropdown-form form-class="p-0">
            <view-manager
              :options="columnsForViewSelector"
              @input="shownColumns = $event;"
            />
          </b-dropdown-form>
        </b-dropdown>

        <!-- SHOW CLEAR ALL FILTERS BUTTON -->                
        <b-button size="sm" class="p-1 mx-1" variant="danger" :title="$t('clearFilters')" @click="clearAll(true);" v-b-tooltip.window >
          <b-icon-filter />
        </b-button>

        <!-- ARCHIVE BUTTON -->
        <b-form-checkbox 
          v-if="!noArchive"
          size="sm" button 
          class="mx-1"
          v-b-tooltip
          :title="$t('archive')"
          v-model="showArchive" 
          @change="$emit('showArchive',$event)"
          :button-variant="showArchive ? 'warning' : 'outline-warning'">
              <b-icon-archive
                :animation="loadingArchive ? 'throb' : ''" 
                variant="dark">
                </b-icon-archive>
        </b-form-checkbox>

        

        <filter-storage 
            class="mx-1" 
            :presetFilters="presetFilters" 
            :defaultFilter="presetDefaultId"
            :currentConfig="currentFilterConfiguration" 
            :cleared="filterCleared"

            @restoreFilter="restoreFilter($event); filterCleared = false"
            @setdefault="updateDefault($event)"
            @forceReset="resetConfiguration($event)"
          />

          
          <base-gantt-2 
            disabled 
            :value="selectedIris" 
            :noOwnerContext="!contextSensitive || noOwner"
            :modal="customUuid+'-ganttOverlay'">
            <template #title><slot name="title">{{ $t('project.gantts') }}</slot></template>
          </base-gantt-2>


          <!--  @slot slot for action buttons in the view block (appended)
                  @binding {Array} columnData the full column configuration array
                  @binding {Array} rowData the currently displayed rows in table configuration
                  @binding {any} slotData properties passed via the slotData property

              -->
            <slot name="buttons-view" 
              v-bind:columnData="allColumns"
              v-bind:rowData="preppedRowData"
              v-bind:selectedIris="selectedIris"
              v-bind:slotData="slotData"></slot>
        </div>


      </div>
      <!--END FILTERBLOCK -->

      <!-- SEARCH BOX -->
      <div v-if="!wrapInCollapseBox" class="d-flex flex-column px-2 border-right border-secondary">
        <div class="small text-muted text-center w-100" style="font-size: 10px;">{{ $t('box.search')}}</div>
        <div class="d-flex flex-row flex-grow-1 align-items-center">
          <slot name="searchbar">
            <b-input-group class="ml-3" style="width: 250px;" size="sm">
              <b-input-group-prepend> 
                <b-input-group-text style="font-size: 0.7em;">
                <b-icon-search scale="1" size="0.8em" />
                </b-input-group-text>
              </b-input-group-prepend>     
              <b-input type="search" v-model="baseSearchTerm" debounce="500"/>
            </b-input-group>
          </slot>
        </div>
      </div>
      
      <div>
        <slot name="complexFilter"></slot>
      </div>

        <b-input-group v-if="pagination && !wrapInCollapseBox" size="sm" class="ml-auto mr-4 w-auto flex-nowrap" :title="$t('miniMonitor')" v-b-tooltip.window>
          <b-input-group-prepend>
            <b-input-group-text>
              {{ filteredRowData.length == 0 ? 0 : (page - 1)*itemsPerPage + 1 }}-{{ Math.min(page*itemsPerPage,filteredRowData.length) }} /
              {{ filteredRowData.length }} / 
              {{ ownerRowData.length }}
            </b-input-group-text> 
          </b-input-group-prepend>
        </b-input-group>
    </aside>
    
    </b-collapse>

    <div v-if="!card" class="d-flex overflow-auto h-100">
      <table class="text-left base--table" ref="mainTable">

        <thead class="text-left text-light table-header--main " :class="{'verticalHeaders' : verticalHeaders}">

          <tr>
            <th class="table-header--main pl-1" :class="{'sticky': !unstickHeader}" 
                style="position:sticky;z-index: 6; left:0;">
              <b-icon-three-dots-vertical v-b-toggle="$id('menu')" v-show="allowToolbarToggle && !fixed" />
              <b-form-checkbox @change="toggleAllVisible($event)" :indeterminate="anySelected" :checked="allVisibleSelected"></b-form-checkbox>
            </th>
            <th 
              v-if="showSubgroups" 
              class="table-header--main pl-1" 
              :class="{'sticky': !unstickHeader}"
              :style="'position:sticky;z-index: 6; left:28px;'"
              ></th>
            <th 
              class="table-header--main pl-1" v-if="!noLink" :class="{'sticky': !unstickHeader}" 
                :style="'position:sticky;z-index: 6; left:'+(showSubgroups ? 43 : 28)+'px;'"></th>

            <!--<slot name="header-prepend"></slot>-->
          
            <base-table-header 
                v-for="(col, idx) in selectedShownColumns"
                :key="idx"
                :index="idx"
                :name="col.name || col.internalName"
                :column="col"
                :rowData="ownerRowData"
                :filteredRowData="filteredRowData"
                :verticalHeaders="verticalHeaders"
                :fixed="fixed"
                :sticky="!unstickHeader"
                :filterData="filterData[col.name || col.internalName] || {}"
                :sortData="getSortData(col.name || col.internalName)"
                :groupData="getGroupData(col.name || col.internalName)"
                @startReordering="startReordering(idx,$event)"
                @dragover="dragover_handler"
                @drop="updateOrder(idx,$event)"
            >      
            
            </base-table-header>

            <!--<slot name="header-append"></slot>-->
          </tr>

        </thead>

        <tbody ref="tableBody" >
          <create-row
            v-if="!!createRow"
            v-bind="createRow"
            :noLink="noLink"
            :columnData="selectedShownColumns"
            :foldingStatus="showSubgroups ? true : null"
            :indent="headerOffset"
            @elementAdded="$emit('elementAdded',$event)"
          />
          <tr v-if="preppedRowData.length == 0 && !loading">
            <td :colspan="enhancedColumns.length + groupData.length + 3">
            {{ $store.getters.getOwnerContext.length > 0 ? $t('no.content') : $t('no.project') }}
            </td>
            </tr>
          <tr 
              v-if="loading && preppedRowData.length == 0">
                <td :colspan="enhancedColumns.length + groupData.length + 3">
                  <!-- @slot specific loading animation/screen -->
                    <slot name="loading"
                      >
                      <b-skeleton width="60%"></b-skeleton>
                      <b-skeleton width="80%"></b-skeleton>
                      <b-skeleton width="75%"></b-skeleton>
                    </slot>
                </td>
            </tr>

          <template v-for="(content, idx) in preppedRowData" >
            <tr 
              :key="$id('row'+'_'+(content['@id'] || idx))" 
              :lvl="content['@indent']" 
              v-if="!content['@hiddenByGroups'] && content['@header']" 
              
              class="baseTable--groupingHeader border-bottom border-top border-dark" >
              <td 
                class="baseTable--shrink"               
                style="position: sticky; left: 0;"
                >
                <b-form-checkbox @change="toggleGroup($event, idx)" />
              </td>
              <td 
                @click="toggleGroupingGroup(content)"
                class="icon baseTable--shrink"     
                style="position: sticky; left: 29px;">
                <b-icon :icon="content['@groupCollapsed'] ? 'chevron-compact-right' :'chevron-compact-down'" variant="dark">
                  </b-icon>
              </td>
              <th class="" 
              :colspan="selectedShownColumns.length + groupData.length">
              <div
                :style="'position: sticky; left: '+(15*content['@indent'] + 29)+'px; width:fit-content;'" >{{ content['@label'] }}</div></th>            
            </tr>
            <base-row-display 
              v-if="!content['@hiddenByGroups'] && !content['@header'] && (!content['@edit'] || immutable)"
              :key="$id('row'+'_'+idx)"
              :id="$id('row'+'_'+idx)"
              :rowData="content"
              :columnData="selectedShownColumns"
              :fullColumnData="allColumns"
              :immutable="immutable || content['@immutable']"
              :totalWidth="fullWidth"
              :noLink="noLink"
              :showDetailAction="showDetailAction"
              :linkTarget="content['@link'] ||false"
              :foldingStatus="showSubgroups ? (content['@subgroup'] || (showSingleRow && isSelector && preppedRowData.length == 1)) : null"
              @editMode="toggleEditModeForRow(content, true)"
              @select="selectHandler(content,$event)"
              :selected="content['@selected']"
              @toggleSubgroup="toggleSubgroup(content)"
              :class="{'subgroup' : !(content['@subgroup'] || (showSingleRow && isSelector && preppedRowData.length == 1)) }"
            >      

              <template #popover >
                <!-- @slot allows append actions to the row popover. This slot is rendered for each row
                    @binding {Array} columns the full column configuration array (includes currently hidden columns)
                    @binding {Object} rowData the current row data

                -->
                <slot name="row-popover" v-bind:columns="allColumns" v-bind:rowData="content"></slot>
              </template>
            </base-row-display>             
            <editable-row 
                v-if="!content['@header'] && content['@edit'] && !immutable" 
                :class="{'subgroup' : (content['@subgroup'] || (showSingleRow && isSelector && preppedRowData.length == 1)) }"
                :key="$id('row'+'_'+idx)"
                :rowData="content"
                :columnData="selectedShownColumns"
                :indent="headerOffset"
                :lvl="content.indent + 1"
                :noLink="noLink"
                :linkTarget="noLink ? false : content['@link']"
                @leaveEditMode="toggleEditModeForRow(content, false)"
                :foldingStatus="showSubgroups ? (content['@subgroup'] || (showSingleRow && isSelector && preppedRowData.length == 1)) : null"
                @toggleSubgroup="toggleSubgroup(content)"
                >
            </editable-row>      
            <tr 
              :key="$id('row-add-'+idx)"
              v-if="(content['@subgroup'] || (showSingleRow && isSelector && preppedRowData.length == 1))"
              class="subwindow"
              :class="{'subgroup' : (content['@subgroup'] || (showSingleRow && isSelector && preppedRowData.length == 1)) }"
              v-show="!content['@hiddenByGroups']">
                <td 
                  :colspan="enhancedColumns.length + groupData.length + 3"                
                  >
                  <div class="p-2">
                    <!-- @slot slot for subitems. This renders a fully customizable div container which can be shown per row
                          @binding {Array} columnData the full column configuration array (including hidden columns)
                          @binding {Object} rowData the data of the current row
                          @binding {Number} indent the indentation of the parent element
                          @binding {Number} lvl the actual level of the parent element
                          @binding {Boolean} immutable whether the current table is in an immutable state
                          @binding {Boolean} if the table is currently in selection mode (called by a selector or similar)
                          @binding {any} slotData properties passed via the slotData property

                      -->
                    <slot name="subcontent"                   
                      v-bind:rowData="content"
                      v-bind:columnData="allColumns"
                      v-bind:indent="headerOffset"
                      v-bind:lvl="content.indent+1"
                      v-bind:immutable="immutable"
                      v-bind:selectionMode="selectionMode"
                      v-bind:slotData="slotData"
                      >
                    </slot>
                  </div>
                </td>
            </tr>
          </template>
          <tr>
            <td :colspan="enhancedColumns.length + groupData.length + 3">&nbsp;
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="h-100 overflow-auto" >
      <b-list-group ref="mainTable" class="overflow-hidden">
          <template v-for="(content, idx) in preppedRowData" >
            <b-list-group-item :key="$id('row'+'_'+idx)" v-if="content['@header']" class="baseTable--groupingHeader" variant="info">
              {{ content['@label'] || 'NO DATA' }}
            </b-list-group-item>
            <b-list-group-item 
              :variant="(selectedIris.indexOf(content.iri.value) > -1) ? 'primary' : ''"
              class="p-0 card--item" 
              :class="content['@class']"
              v-else 
              v-show="!content.collapsed" 
              :key="$id('row'+'_'+idx)" 
              @click="selectHandler(content)" >
              <div class="d-flex justify-items-start">
                <div class="pl-1 pt-1">
                  <b-form-checkbox
                    @change="selectHandler(content,$event)"
                    :selected="content['@selected']" />
                </div>
                <div class="flex-grow-1">
                  <!-- @slot the card definition for the card view
                      @binding {Array} columnData the full column configuration array
                      @binding {Array} rowData the current row
                  -->
                  <slot name="card-definition" v-bind:rowData="content"  v-bind:columnData="enhancedColumns"></slot>
                </div>
              </div>
            </b-list-group-item>
            </template>
      </b-list-group>
    </div>    
     <portal to="main-status-target" class="align-items-center border-left border-right d-flex flex-column justify-content-center px-1">
       <!-- @slot add monitoring data to the footer of the app
            @binding {any} the data passed to the baseTable as a property
            @binding {Object[]} rowData the full row array 
        -->
       <slot name="monitor" v-bind:rowData="preppedRowData" v-bind:filteredRowData="filteredRowData" v-bind:slotData="slotData"></slot>
    </portal>
  </div>
  </component>
</template>

<style lang="scss">
@import "../../style/customvars";
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import '../../../node_modules/bootstrap-vue/src/index.scss';



aside.table--menu {
  top: 0px; 
}

.base-table--title-bar {
  background-color: $gray-400;
}

tr.base-table--row:hover .base-table--cell{
    background-color: $hover-color !important;
}
tr.base-table--row.subgroup {
  @extend .alert-primary;
}

tr.base-table--row:not(.subgroup).highlightRow:nth-child(n) {
  & > td {
    @extend .alert-success;
    color: black;
  }
}

tr.base-table--row:not(.subgroup).highlightRow--success:nth-child(n) {
  & > td {
    @extend .alert-success;
    color: black;
  }
}

tr.base-table--row:not(.subgroup).highlightRow--primary:nth-child(n) {
  & > td {
    @extend .alert-primary;
    color: black;
  }
}

tr.base-table--row:not(.subgroup).highlightRow--customer:nth-child(n) {
  
  & > td {
    background-color: #88b0ec;
    color: black;
  }
}

tr.base-table--row:not(.subgroup).highlightRow--danger:nth-child(n) {
  & > td {
    @extend .alert-danger;
    color: black;
  }
}

tr.base-table--row:not(.subgroup).highlightRow--secondary:nth-child(n) {
  & > td {
    @extend .alert-secondary;
    color: black;
  }
}

tr.base-table--row:not(.subgroup).highlightRow--warning:nth-child(n) {
  & > td {
    @extend .alert-warning;
    color: black;
  }
}


tr.base-table--row:not(.subgroup):nth-child(even) .base-table--cell {
  background-color: darken($light, 2%);
}

tr.base-table--row:not(.subgroup):nth-child(odd) .base-table--cell {
  background-color: $light;
}


tr.subwindow.subgroup {
    height: 1px;
  @extend .alert-primary;
  & > td {
    color: black;
  }
}


  @for $i from 1 through 5 {
    tr.baseTable--groupingHeader[lvl="#{$i}"] {      
      background-color: lighten($secondary, ($i+2) * 5%);
    }
  }

tr.baseTable--groupingHeader > td {
  padding: 3px 0;
}

td.baseTable--shrink {
  width:1px;
}

.card--item:hover {
  background-color: $hover-color;
}

.verticalHeaders {
  height: 200px;
  .table-header--main {
    vertical-align: bottom;

    .multiline {
      height: 20px;
    }

    .textBox {
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 165px;          
          min-width: 165px;          
          width: 165px;          
          transform-origin: bottom left;
          transform: rotate(-90deg);


          &.multi-2 {
               transform: rotate(
                -90deg
                ) translateY(20px) translateX(20px);
          }
    }

    .table-text-ellipsis {
      overflow:initial;
      display: flex;
      justify-content: flex-start;
    }
  }
}

.table-header--main {
  @extend .alert-secondary; 
  vertical-align: baseline;

  th {
    @extend .text-left;
    @extend .alert-secondary; 
    @extend .text-dark;
    z-index: 2;
    top:0;
  }

  th.sticky {
    position:sticky;
    z-index: 3;
  }
}

.table-text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

tr[collapsed=true]:not(.baseTable--groupingHeader), tr.subgroup[collapsed=true] {
  display: none;
}

.baseTable--groupingHeader {
  font-weight: bold;
}
.baseTable--groupingIndent {
  width: 2em;
}

.base-table--wrapper {
  align-self: start;
}

.base-table--cell {
  width: 1px;
  background-color: $light;
}

.base-table--row {
  @extend .border-bottom;
  @extend .p-1;
}

.baseTable--groupingHeader , .base-table--row {
  height: 25px;
}

.base--table {
  table-layout: fixed;
}
</style>


<script>
import tableRowOrganizer from './Configurations/rowManager'
import viewConfiguration from './Configurations/viewConfiguration'
import selectionHandler from './Configurations/selectionHandler'
import componentMixin from './subcomponents/subcomponents'
import editableRow from './subcomponents/EditableRow'
import createRow from './subcomponents/CreateRow'
import baseRowDisplay from './subcomponents/BaseRowDisplay'
import viewManager from './subcomponents/_viewManager'

import filterStorage from '@/components/BaseFilterManager/FilterStorage'

import moment from 'moment'
import CreateRow from './subcomponents/CreateRow.vue'
import BaseExporter from '../BaseExporter/BaseExporter.vue'

import MailSender from '@/components/BaseMailer/MailSender'
import BaseGantt2 from '../BaseGantt2/BaseGantt2.vue'

import BatchProcessor from './subcomponents/BatchProcessor/BatchProcessor.vue'

/** 
 * @displayName BaseTable
 * @event showArchive emitted when the showArchive button is toggled. Gives the state as event property
 * @event elementAdded is fired whenever a new element in the createRow was created
 * @event hideSubgroup sends the iri of the hidden subgroups parent to the parent component
 * @event showSubgroup sends the iri of the shown subgroups parent to the parent component
*/
export default {
  name: "base-table",
  mixins: [viewConfiguration, selectionHandler, tableRowOrganizer, componentMixin],
  components: {    
      "editableRow": editableRow,
      "createRow": createRow,
      "baseRowDisplay": baseRowDisplay,
      "viewManager": viewManager,
      "filterStorage": filterStorage,
      BaseExporter,
      MailSender,
      BatchProcessor,
    BaseGantt2
  },
  props: 

        {
      customUuid: { type: String, default: function() {return this.$id()}},
      /**
       * Switches the table to card mode
       */
      card: { type: Boolean, default: false },
      /**
       * defines if the data should be immutable, e.g. not open the edit row
       */
      immutable: {type: Boolean, default: false },
      /**
       * toggle the pagination.
       */
      pagination: {type: Boolean, default: true},
      /**
       * hide the complete action bar
       */
      noHeader: {type: Boolean, default: false },
      /**
       * hide the title bar 
       */
      noTitle: {type: Boolean, default: false },
      /**
       * remove the link column
       */
      noLink: {type: Boolean, default: false },
      noSubElement: { type: Boolean, default: false},

      /**
       * disable sticky mode of the header. Should only 
       * be used in some edge cases
       */
      unstickHeader: {type: Boolean, default: false},

      /**
       * hide the archive button
       */
      noArchive: {type: Boolean, default: false},

      /**
       * hide the screenshot button
       */
      noScreenshot: {type: Boolean, default: false},

      /**
       * show the excel export button
       */
      allowExport: { type: Boolean, default: false},
      
      /**
       * show the batch managing button
       */
      allowBatchProcessing: { type: Boolean, default: false},
      
      /**
       * show the batch_relation_processing button
       */
      allowBatchRelationProcessing: {trype: Boolean, default: false},
      /**
       * do batch preProcessing in relative module
       */
       batchPreProcessor:{
            type:Function,
            required:false,
            default: (a,b) => b
        },

      /**
       * show the excel export button
       */
      allowTimetable: { type: Boolean, default: false},

      allowToolbarToggle: { type: Boolean, default: true },

      wrapInCollapseBox: {
        type: Boolean,
        default: false,
        required: false
      },

      title: {
        type: String,
        required: false,
        default: "ERROR NO TITLE"
      },

      /**
       * any data that should be passed to some slots
       */
      slotData: {type: null, default: () => ({})},
      /**
       * fixed tables can't be resized. Make sure that each column has
       * its width set
       */
      fixed: {type: Boolean, default: false },
      /**
       * hide the switchToDetails button in the action row
       */
      noSwitchToDetails: {type: Boolean, default: false },
      
      /**
       * activate the per row popover. This is always displayed to the left 
       * @see BaseRowDisplay
       */
      showDetailAction: {type: Boolean, default: false },

      showSingleRow: { type: Boolean , default: false },

      /**
       * set the route to which the backTo button should take the user
       */
      backTo: {type: String, default: "" },

      /**
       * toggles the loading state of the table and displays the loading screen
       */
      loading: {type: Boolean, default: false},

      /**
       * toggles the loading state of the archive icon
       */
      loadingArchive: {type: Boolean, default: false},

      /**
       * If defined a permanent create row will be displayed on top
       * The exact configuration is defined in createRow
       * @see CreateRow  
       */
      createRow: {
        required: false,
        type: [Object, Boolean], 
        default: false
      },
      /**
       * moved to each component table
       * @deprecated
       */
      filter: {
        required: false,
        type: [Object],
        default: () => ({})
      },
      /**
       * Allows headers to be aligned vertically
       */
      verticalHeaders: { type: Boolean, default: false },

      presetFilters: { type: Array, default: () => []},
      presetDefaultId : {type: [Boolean, Number, String], default: false}
  },
  inject: {
    "getOrigin" : "getOrigin",
    "customizeTable" : {name: "customizeTable", default: false},
    "defaultSortHandler" : {name: "defaultSortHandler", default: () => (a,b) => (b && b.id && b.id.value || 0) - (a && a.id && a.id.value || 0)},
    "storeData" : { name: "storeData", default: false },
    "isSelector" : {name: "isSelector", default: false },
    "fetchByPage" : {name: "fetchByPage", default: false }
  },
  methods: {  
    toggleStickyByName: function(colName) {
      let foundColumn = this.selectedShownColumns.find(c => c.name == colName)
      if(foundColumn) {
        this.toggleSticky(foundColumn)
      }
    },
    toggleSticky: function(col) {
      this.$set(col,'@sticky',!(col['@sticky'] ?? false))
      if(col['@sticky']) {
        this.stickyColumnNames.add(col.name)
      } else {
        this.$set(col,'@stickyStyle','')
        this.stickyColumnNames.delete(col.name)

      }
    },
    renderScreenshot: async function(ref) {
      this.renderingScreenshot = true
      await this.$screenshot.render(this.$refs[ref])
      this.renderingScreenshot = false
    },
    forceReloadData: async function(){
      if(this.isReloading) return

      this.isReloading = true
      let moduleName = this.getOrigin().module
      await this.$libraries.forceStoreReinitialization(moduleName)     
      this.$root.$emit('bts::mercure::restart',true) 
      this.$bvToast.toast(this.$t('reload.successful'), {variant: "warning"})
      this.isReloading = false

    },
    switchToDetails: function() {
      let targetUrl = null
      let targetItem = null
      let targetIri = null
      if(this.preppedRowData.length > 0) {
        let k = 0
        while(this.preppedRowData[k] && this.preppedRowData[k]['@header']) {
          k++
        }
        targetItem = this.preppedRowData[k]
      } 
      let selected = this.selectedIris
      if(selected.length > 0) {
        targetIri = selected[0]
        targetItem = this.preppedRowData.find(row => row?.iri?.value == targetIri)
      }

      if(targetItem && targetItem['@link']) {
       if(targetItem['@link'].url) {
         targetUrl = targetItem['@link'].url 
       } else if(typeof targetItem['@link'] === "string") {
         targetUrl = targetItem
       }
      } else if(targetItem && targetItem.iri) {
        targetUrl = targetItem.iri.hasOwnProperty('value') ? targetItem.iri.value : targetItem.iri
      }

      if(targetUrl !== null && targetUrl !== this.$route.path) {
        this.$router.push(targetUrl)
      }
    },
    selectHandler: function(content, ev = null) {
      if(ev !== null && !!(content?.iri?.value ?? content?.iri)) { 
        this.updateSelection((content?.iri?.value ?? content?.iri), ev) 
      } else {
      this.$store.commit('setLastSelectedItem', {item: content, origin: this.getOrigin()})
      }
    },
    toggleEditModeForRow(content, toggleTo = null) {
      let idx = this.editList.indexOf(content.iri.value)
      if(idx > -1 && (toggleTo === null || toggleTo === false)) {
        this.editList.splice(idx,1)
      } else if(toggleTo === null || toggleTo === true) {
        this.editList.splice(this.editList.length,0,content.iri.value)
      }
    },
    toggleSubgroup(content, toggleTo = null) {
      let idx = this.subgroups.indexOf(content.iri.value || content.iri)
      if(idx > -1 && (toggleTo === null || toggleTo === false)) {
        this.subgroups.splice(idx,1)
        this.$emit('hideSubgroup',content.iri.value || content.iri)
      } else if(toggleTo === null || toggleTo === true) {
        this.subgroups.splice(this.subgroups.length,0,content.iri.value)
        this.$emit('showSubgroup',content.iri.value || content.iri)
      }
    },
    toggleGroupingGroup(content, toggleTo = null) {
      let idx = this.foldedGroupings.indexOf(content['@groups'])
      if(idx > -1 && (toggleTo === null || toggleTo === false)) {
        this.foldedGroupings.splice(idx,1)
      } else if(toggleTo === null || toggleTo === true) {
        this.foldedGroupings.splice(this.foldedGroupings.length,0,content['@groups'])
      }
    },
    applyFilter: function(filter) {
     /* removed since view configuration is not supposed to be restored from filter.
      For now, keep the save settings, but dont apply them on load
     
     this.widths = filter['widths']
      this.columnOrder = filter['order']
      filter['show'].forEach(elem => {
        let toChangeCol = this.allColumns.find(col => col.name === elem.name)
        if(toChangeCol) {
          toChangeCol.show = elem.show
        }
      }) */
      this.fullFilterConfiguration = filter['filters']
    },
    restoreFilter: function(filterConfig) {
      //this.clearAll(false)
      //this.loadDefault()
      this.currentFilterConfiguration = JSON.parse(JSON.stringify(filterConfig ?? {}))
      this.buildFilteredRows()
    },
    startResize: function(colName) {
      this.resizedColumn = colName
      this.tmpWidth = this.getColumnByName(colName)['@width'] || 16
      document.addEventListener('mousemove',this.updateWidth)
      document.addEventListener('mouseup',this.removeListeners)

    },
    updateWidth: function(ev) {   
      ev.preventDefault()   
      this.tmpWidth = Math.max(this.tmpWidth || 0,16) + ev.movementX 
      this.$set(this.widths, this.resizedColumn, this.tmpWidth)
    },
    removeListeners: function() {
      document.removeEventListener('mousemove',this.updateWidth)
      document.removeEventListener('mouseup',this.removeListeners)
      this.$set(this.widths, this.resizedColumn, this.tmpWidth)
    },
    fetchFilters: function() {
      this.initializeFiltersUnwatch()
      let module = this.getOrigin()['module']
      let widget = this.getOrigin()['widget']
      let filters = this.$store.getters['user/getFiltersForModule'](module) || []
      this.storedFilters = filters[widget] || []
    },
    updateDefault: function(def) {
      this.$set(this,'defaultSetup', JSON.parse(JSON.stringify(def)))
      this.loadDefault()
    },
    startReordering(idx,ev) {
            ev.stopPropagation();
            ev.dataTransfer.dropEffect = "move"
            ev.dataTransfer.setData('application/json',JSON.stringify({id: idx,  action: "ordering"}))
        },
        dragover_handler(ev) {
            ev.preventDefault();
            if(this.disabled) {
                ev.dataTransfer.dropEffect = "none";
                return
            } else {
                ev.dataTransfer.dropEffect = "move";
            }
        },
        updateOrder(targetIdx, ev) {
            
            let transferString = ev.dataTransfer.getData('application/json')
            if(!transferString) {return}
            let transferObject = JSON.parse(transferString)
            let droppedId = transferObject.id
            let dragAction = transferObject.action         
            if(dragAction !== "ordering" || targetIdx == droppedId ) { return }
            this.reorder(targetIdx, droppedId) ;
        },
        reorder(targetId, droppedId) {
             
            let newArray 
            if(this.shownColumns !=null ){
             newArray = [...this.shownColumns]
            }
            else{
              newArray =  [...this.allColumns]

            }

                let targetOldOrder = newArray[targetId].order
                let dropOldOrder = newArray[droppedId].order
               
                if(targetOldOrder < dropOldOrder)
                {
                for(let i= targetId; i< droppedId; i++){
                    newArray[i].order++
                }
                }
                if(dropOldOrder < targetOldOrder ){
                for(let i= droppedId+1; i<= targetId; i++){
                    newArray[i].order--     
                }
                }
                newArray[droppedId].order = targetOldOrder
                
            this.shownColumns = newArray 
            //this.$set(this,'shownColumns',newArray)
        },
  },
  computed: {
      fullWidth: function(vm) {
        return vm.selectedShownColumns.reduce((a,b) => a + b['@width'],0)
      },
      showSubgroups: function(vm) {
        return (!!vm.$scopedSlots['subcontent'] ?? !!vm.$slots['subcontent'] ?? vm.noSubElement)
      }
  },
  created() {
    this.initializeFiltersUnwatch = this.$watch(function() { return this.$store.hasModule('user') && this.$store.getters.isAuthenticated }, this.fetchFilters)
  },
  data() {
    return {
      page: 1,
      itemsPerPage: 25,
      storedFilters: [],
      stickyColumnNames: new Set(),
      resizedColumn: null,
      tmpWidth: 0,
      toStoreFilterName: "",
      savingFilter: false,
      serverPulling: false,
      initializeFiltersUnwatch: null,
      showArchive: false,
      renderingScreenshot: false,
      defaultSetup: null,
      isReloading: false,
      filterCleared: true
    }
  }
}
</script>
