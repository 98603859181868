<i18n locale="en" lang="yaml">
    cw: "CW: "
    button.eow: "End of week"
    button.eonw: "End of next week"
    button.eom: "End of month"
    button.addoneweek: "+1 week"
    button.addtwoweeks: "+2 weeks"
    button.addonemonth: "+4 weeks"
</i18n>

<i18n locale="de" lang="yaml">
    cw: "KW: "
    button.eow: "Ende der Woche"
    button.eonw: "Ende nächster Woche"
    button.eom: "Ende des Monats"
    button.addoneweek: "+1 Woche"
    button.addtwoweeks: "+2 Wochen"
    button.addonemonth: "+4 Wochen"
</i18n>
<template>
    <div 
        class="dateContainer d-flex" 
        ref="ddnew"
        v-b-hover="toggleShow"
         >
        <b-input-group 
        class="border border-secondary rounded  d-flex align-items-center"
        :class="{'border-danger' : !dateValid && !disabled && !optional, 'border-success' : dateValid, 'small' : size == 'sm' }"
             >
            <b-input-group-prepend v-if="showCw && momentDate" v-b-tooltip title="KW" class="text-nowrap">
                <b-input-group-text class="py-0 border-0"
                style="font-size: 0.7rem;line-height: 1.35rem;">{{ $t('cw') + ((momentDate && momentDate.isValid() && momentDate.format('WW')) || "-" )}}</b-input-group-text>
            </b-input-group-prepend>
            <date-input
                class="d-inline" 
                v-model="momentDate" 
                @input="updateValue()"
                :size="size"
                :disabled="disabled"
                
            />

            <b-button 
                
                :variant="dateValid ? 'success' : 'danger'" 
                :size="size"
                :class="{'d-none' : !showDropdown }"
                @click="handleClick"
            >
                <b-icon-calendar></b-icon-calendar>
            </b-button>
            <portal to="base-date-picker" >
                <div 
                    class="bg-white p-2 border-secondary rounded position-fixed shadow-sm"
                    :class="{
                        'd-none' : !showCalendar

                    }"
                    :style="computedStyle"
                    >
                <calendar
                    :id="$id('calendar')"
                    v-model="momentDate" 
                    @input="updateValue()">
                        <template #topbuttons>
                            <b-button-group size="sm"  class="w-100 mb-2">
                                <b-button variant="primary" @click="quickChangeDate('eow')">{{ $t('button.eow')}}</b-button>
                                <b-button variant="primary" @click="quickChangeDate('eonw')">{{ $t('button.eonw')}}</b-button>
                                <b-button variant="primary" @click="quickChangeDate('eom')">{{ $t('button.eom')}}</b-button>
                            </b-button-group>
                        </template>
                        <template #bottombuttons>
                            <b-button-group size="sm"  class="w-100 mb-2">
                                <b-button variant="primary" @click="quickChangeDate('+1w')">{{ $t('button.addoneweek')}}</b-button>
                                <b-button variant="primary" @click="quickChangeDate('+2w')">{{ $t('button.addtwoweeks')}}</b-button>
                                <b-button variant="primary" @click="quickChangeDate('+4w')">{{ $t('button.addonemonth')}}</b-button>
                            </b-button-group>
                        </template>
                    </calendar>
                
                </div>
            </portal>
        </b-input-group>   

            
                 
    </div>
</template>

<script>
import Calendar from './subcomponents/Calendar.vue'

const moment = require('moment')

import DateInputField from './subcomponents/InputField'

export default {
    name: "BaseDatePicker",
    props: {
        value: {
            required: false,
            type: [String, Object]
        },
        default: {
            required: false,
            type: String
        },
        showCw: {
            required: false,
            type: Boolean,
            default: true
        },
        disabled: {
            required: false,
            type: Boolean,
            default: false
        },
        size: {
            required: false,
            type: String,
            default: "sm"
        },
        optional: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    components: {
        'dateInput' : DateInputField,
        'calendar' : Calendar
    },
    data() {
        return {
            momentDate: moment(),
            showDropdown: false,
            lockedDropdown: false,
            showCalendar: false,
            positionData: {
                xInput: 0,
                yInput: 0,
                wInput: 0,
                hInput: 0,
                wCalendar: 0,
                hCalendar: 0
            }
        }
    },
    watch: {
        'value': {
            immediate: true,
            handler: function(newVal) {
            this.$set(this,'momentDate',newVal ? moment(newVal).locale(this.locale) : (this.default ? moment(this.default).locale(this.locale) : null))
            }
        }
    },
    computed: {
        locale: function(vm) {
            return ["de","en-gb"]
        },
        dateValid: function(vm) {
            return vm.momentDate && vm.momentDate.isValid && vm.momentDate.isValid()
        },
        computedStyle: function(vm) {
            let styleString = "z-index: 5000;"
            //place the calendar below the button aligned right
            let bottomEdge = vm.positionData.yInput + vm.positionData.hInput
            let leftEdge = vm.positionData.xInput - vm.positionData.wCalendar + vm.positionData.wInput

            if((bottomEdge + vm.positionData.hCalendar) > window.innerHeight ) {
                //flip position to be above the input field
                bottomEdge = vm.positionData.yInput - vm.positionData.hCalendar - 18
            }

            if(leftEdge < 0 ) {
                leftEdge = vm.positionData.xInput;
            }

            styleString += "left: " + (leftEdge - 18) + "px;"
            styleString += "top: " + (bottomEdge) + "px;"

            //window.innerHeight
            //window.innerWidth


            return styleString
        },
    },
    methods: {
        handleClick: function() {
            this.showCalendar = !this.showCalendar
            this.$nextTick(() => 
                this.$nextTick(() => 
                    this.$nextTick(() => this.recomputePositionData())

                )
            )
            window.addEventListener('scroll', function() { 
                this.recomputePositionData.bind(this) 
            }.bind(this))

        },
        recomputePositionData: function() {
            let calendar = document.getElementById(this.$id('calendar'))
            let calendarBox = calendar?.getBoundingClientRect()
            let buttonPosition = this.$refs.ddnew?.getBoundingClientRect();

            this.positionData.hInput = buttonPosition.height
            this.positionData.wInput = buttonPosition.width
            this.positionData.xInput = buttonPosition.x
            this.positionData.yInput = buttonPosition.y
            this.positionData.wCalendar = calendarBox?.width ?? 0
            this.positionData.hCalendar = calendarBox?.height ?? 0

        }, 
        toggleShow: function(hovered) {
            this.showDropdown = hovered 
        },
        lockDropdown: function(mode) {
            this.lockedDropdown = mode

            if(mode) {
                this.$refs.dd.show(true) 
                this.$emit('focus',mode)
            } else {
                this.$emit('blur',mode)
            }
        },
        quickChangeDate: function(target) {
            let tmp = moment(this.momentDate)
            if(!this.dateValid) {
                tmp = moment().startOf('day')
            }
            switch(target) {                
                //End Of Week
                case "eow": 
                    //set tmp to today and move to friday
                    tmp = moment().startOf('day')
                    tmp.endOf('week').subtract(1,'days').startOf('day') //set to friday of this week
                    break;
                //End Of Next Week
                case "eonw":
                    //set tmp to today and move to friday next week
                    tmp = moment().startOf('day')
                    tmp.endOf('week').add(6,'days').startOf('day')  //set to friday of next week
                    break;
                //End Of Month
                case "eom":
                    tmp = moment().endOf('month').startOf('day') 
                    //move date to a friday if it is on a weekend
                    let tmpWeekday = parseInt(tmp.format('E'))
                    if(tmpWeekday > 5) {
                        tmp.add(5 - tmpWeekday,'days')
                    }
                    break;
                //add one week
                case "+1w":
                    tmp.add(1,'week')
                    break;
                case "+2w":
                    tmp.add(2,'week')
                    break;
                case "+4w":
                    tmp.add(4,'week')
                    break;

            }


            this.momentDate = tmp.locale(this.locale)
            this.updateValue()
        },
        updateValue: function() {
            if(this.momentDate === null) {
                this.$emit('input',null);
            } else {
                this.$emit('input',this.momentDate && this.momentDate?.isValid() && this.momentDate.format('YYYY-MM-DD') || null)
            }
            this.showCalendar = false
        }
    }

}
</script>

<style lang="scss">
    .hoverbuttons-calendar {
        font-size: 8px; 
        right: 0; 
        top:-1px; 
        font-size: 8px; 
        z-index: 3;
    }
</style>