export default [
    {
        "path" : "\/prototype_orders\/:id",
        "name" : "prototypeOrdersSingle",
        "meta" : {
            "show" : {"sidebar" : false },
            "label" : {"de" : "Musterbauaufträge", "en" : "Prototype tickets"},
            "requiresAuthentication" : true,
            "gridLayout" : [                
                {
                    "x": 4,
                    "y": 0,
                    "w": 12,
                    "h": 6,
                    "minW": 7,
                    "minH" : 4,
                    "i": "proS",
                    "static": false,
                    "module": "production",
                    "widget": "PrototypeOrderSingle",
                    "customProperties": {
                    }           
                }
            ]
        }
    },{
        "path" : "\/prototype_orders",
        "name" : "prototypeOrdersTable",
        "meta" : {
            "show" : {"sidebar" : true, "group": "administration" },
            "label" : {"de" : "Musterbauaufträge", "en" : "Prototype tickets"},
            "requiresAuthentication" : true,
            "suffixComponent": { 
                "widget": "notificationDisplay",
                "customProperties": {
                    "dataSources": [
                        {"module": "production", "context": {"mode" : "prototype_orders"} }
                    ]
                }
            },
            "gridLayout" : [
                {
                    "x": 0,
                    "y": 0,
                    "w": 10,
                    "h": 6,
                    "minW" : 3,
                    "minH" : 2,
                    "i": "taT",
                    "static": false,
                    "module" : "production",
                    "widget" : "PrototypeOrderTable",
                    
                    "customProperties": {
                        "openAsModal" : true
                    },
                    "props" : {
                        "fullHeight" : true,
                        "neverOverflow" : true
                    }
                }
            ],
            "allowAccess": function() {
                    let userRoles = window?.globalVue?.$store.getters.getCurrentUserRoles ?? []
                    return (userRoles.includes('BTS_USER')) ?? false
                }
        }
    },{
        "path" : "\/travel\/:id",
        "name" : "travelSingle",
        "meta" : {
            "show" : {"sidebar" : false, "group": "administration" },
            "label" : {"de" : "Reiseanträge", "en" : "Travel application"},
            "requiresAuthentication" : true,            
            "suffixComponent": { 
                "widget": "notificationDisplay",
                "customProperties": {
                    "dataSources": [
                        {"module": "production", "context": {"mode" : "travel"} }
                    ]
                }
            },
            "gridLayout" : [
                {
                    "x": 0,
                    "y": 0,
                    "w": 10,
                    "h": 6,
                    "minW" : 3,
                    "minH" : 2,
                    "i": "prodTravelS",
                    "static": false,
                    "module" : "production",
                    "widget" : "TravelApplication",
                    
                    "customProperties": {
                        "noDetail" : true
                    },
                    "props" : {
                        "fullHeight" : true,
                        "neverOverflow" : true
                    }
                }],
            "allowAccess": function() {
                    let userRoles = window?.globalVue?.$store.getters.getCurrentUserRoles ?? []
                    return (userRoles.includes('BTS_USER') || userRoles.includes('ATERA_USER') || userRoles.includes('ADMINISTRATION_USER')) ?? false
                }
        }
    },{
        "path" : "\/travel",
        "name" : "travelTable",
        "meta" : {
            "show" : {"sidebar" : true, "group": "administration" },
            "label" : {"de" : "Reiseanträge", "en" : "Travel application"},
            "requiresAuthentication" : true,            
            "suffixComponent": { 
                "widget": "notificationDisplay",
                "customProperties": {
                    "dataSources": [
                        {"module": "production", "context": {"mode" : "travel"} }
                    ]
                }
            },
            "gridLayout" : [
                {
                    "x": 0,
                    "y": 0,
                    "w": 10,
                    "h": 6,
                    "minW" : 3,
                    "minH" : 2,
                    "i": "prodTravelT",
                    "static": false,
                    "module" : "production",
                    "widget" : "TravelApplicationTable",
                    
                    "customProperties": {
                        "noDetail" : true
                    },
                    "props" : {
                        "fullHeight" : true,
                        "neverOverflow" : true
                    }
                }],
            "allowAccess": function() {
                let userRoles = window?.globalVue?.$store.getters.getCurrentUserRoles ?? []
                return (userRoles.includes('BTS_USER') || userRoles.includes('ATERA_USER') || userRoles.includes('ADMINISTRATION_USER')) ?? false
                }
        }
    },{
        "path" : "\/employeeAvailability",
        "name" : "employeeAvailability",
        "meta" : {
            "show" : {"sidebar" : true, "group": "administration" },
            "label" : {"de" : "BTS-Verfügbarkeit", "en" : "BTS availability"},
            "requiresAuthentication" : true,         
            "gridLayout" : [
                {
                    "x": 0,
                    "y": 0,
                    "w": 10,
                    "h": 6,
                    "minW" : 3,
                    "minH" : 2,
                    "i": "prodemployees",
                    "static": false,
                    "module" : "production",
                    "widget" : "EmployeeAvailabilityCalendar",
                    
                    "customProperties": {
                        "noDetail" : true
                    },
                    "props" : {
                        "fullHeight" : false,
                        "neverOverflow" : false
                    }
                }],
                "allowAccess": function() {
                    let userRoles = window?.globalVue?.$store.getters.getCurrentUserRoles ?? []
                        return userRoles.includes('BTS_USER') ?? false
                },
        }
    },{
        "path" : "\/b_as\/:id",
        "name" : "baSingleIri",
        "meta" : {
            "show" : {"sidebar" : false },
            "label" : {"de" : "Musterbauaufträge", "en" : "Prototype tickets"},
            "requiresAuthentication" : true,
            "gridLayout" : [                
                {
                    "x": 0,
                    "y": 0,
                    "w": 10,
                    "h": 6,
                    "minW": 7,
                    "minH" : 4,
                    "i": "prodBAS",
                    "static": false,
                    "module": "production",
                    "widget": "SingleBA",
                    "customProperties": {
                    }           
                }
            ],
            "allowAccess": function() {
                let userRoles = window?.globalVue?.$store.getters.getCurrentUserRoles ?? []
                    return (userRoles.includes('BTS_USER') || userRoles.includes('ADMINISTRATION_USER')) ?? false
            }
        }
    },{
        "path" : "\/ba\/:id",
        "name" : "baSingle",
        "meta" : {
            "show" : {"sidebar" : false },
            "label" : {"de" : "Musterbauaufträge", "en" : "Prototype tickets"},
            "requiresAuthentication" : true,
            "gridLayout" : [                
                {
                    "x": 0,
                    "y": 0,
                    "w": 10,
                    "h": 6,
                    "minW": 7,
                    "minH" : 4,
                    "i": "prodBAS",
                    "static": false,
                    "module": "production",
                    "widget": "SingleBA",
                    "customProperties": {
                    }           
                }
            ],
            "allowAccess": function() {
                let userRoles = window?.globalVue?.$store.getters.getCurrentUserRoles ?? []
                    return (userRoles.includes('BTS_USER') || userRoles.includes('ADMINISTRATION_USER')) ?? false
            }
        }
    },{
        "path" : "\/ba",
        "name" : "baTable",
        "meta" : {
            "show" : {"sidebar" : true, "group": "administration" },
            "label" : {"de" : "BA-Liste", "en" : "BA list"},
            "requiresAuthentication" : true,              
            "suffixComponent": { 
                "widget": "notificationDisplay",
                "customProperties": {
                    "dataSources": [
                        {"module": "production", "context": {"mode" : "bas"} }
                    ]
                }
            },       
            "gridLayout" : [
                {
                    "x": 0,
                    "y": 0,
                    "w": 10,
                    "h": 6,
                    "minW" : 3,
                    "minH" : 2,
                    "i": "prodBAT",
                    "static": false,
                    "module" : "production",
                    "widget" : "TableBA",
                    
                    "customProperties": {
                        "noDetail" : true
                    },
                    "props" : {
                        "fullHeight" : true,
                        "neverOverflow" : false
                    }
                }],
            "allowAccess": function() {
                let userRoles = window?.globalVue?.$store.getters.getCurrentUserRoles ?? []
                return (userRoles.includes('BTS_USER') || userRoles.includes('ADMINISTRATION_USER')) ?? false
            }
        }
    },{
        "path" : "\/invoices\/:id",
        "name" : "invoiceSingle",
        "meta" : {
            "show" : {"sidebar" : false, "group": "administration" },
            "label" : {"de" : "Rechnungsliste", "en" : "Invoice list"},
            "requiresAuthentication" : true,                    
            "suffixComponent": { 
                "widget": "notificationDisplay",
                "customProperties": {
                    "dataSources": [
                        {"module": "production", "context": {"mode" : "invoices"} }
                    ]
                }
            },       
            "gridLayout" : [
                {
                    "x": 0,
                    "y": 0,
                    "w": 10,
                    "h": 6,
                    "minW" : 3,
                    "minH" : 2,
                    "i": "prodInvoicesS",
                    "static": false,
                    "module" : "production",
                    "widget" : "SingleInvoice",
                    
                    "customProperties": {
                        "noDetail" : true
                    },
                    "props" : {
                        "fullHeight" : true,
                        "neverOverflow" : false
                    }
                }],
            "allowAccess": function() {
                let userRoles = window?.globalVue?.$store.getters.getCurrentUserRoles ?? []
                return (userRoles.includes('BTS_USER') || userRoles.includes('ADMINISTRATION_USER')) ?? false

            }
        }
    },{
        "path" : "\/invoices",
        "name" : "invoiceTable",
        "meta" : {
            "show" : {"sidebar" : true, "group": "administration" },
            "label" : {"de" : "Rechnungseingang", "en" : "Incoming invoices"},
            "requiresAuthentication" : true,                    
            "suffixComponent": { 
                "widget": "notificationDisplay",
                "customProperties": {
                    "dataSources": [
                        {"module": "production", "context": {"mode" : "invoices"} }
                    ]
                }
            },       
            "gridLayout" : [
                {
                    "x": 0,
                    "y": 0,
                    "w": 10,
                    "h": 6,
                    "minW" : 3,
                    "minH" : 2,
                    "i": "prodInvoicesT",
                    "static": false,
                    "module" : "production",
                    "widget" : "TableInvoice",
                    
                    "customProperties": {
                        "noDetail" : true
                    },
                    "props" : {
                        "fullHeight" : true,
                        "neverOverflow" : false
                    }
                }],
            "allowAccess": function() {
                return window?.globalVue?.$store.getters.getCurrentUserRoles?.includes('BTS_ACCESS_FINANCE') ?? false

            }
        }
    },{
        "path" : "\/order_invoices",
        "name" : "outgoingInvoiceTable",
        "meta" : {
            "show" : {"sidebar" : true, "group": "administration" },
            "label" : {"de" : "Rechnungsausgang", "en" : "Outgoing Invoices"},
            "requiresAuthentication" : true,             
            "gridLayout" : [
                {
                    "x": 0,
                    "y": 0,
                    "w": 10,
                    "h": 6,
                    "minW" : 3,
                    "minH" : 2,
                    "i": "prodOutgoingInvoices",
                    "static": false,
                    "module" : "production",
                    "widget" : "TableOrderInvoice",
                    
                    "customProperties": {
                        "noDetail" : true
                    },
                    "props" : {
                        "fullHeight" : true,
                        "neverOverflow" : false
                    }
                }],
            "allowAccess": function() {
                return window?.globalVue?.$store.getters.getCurrentUserRoles?.includes('BTS_ACCESS_FINANCE') ?? false

            }
        }
    },{
        "path" : "\/projects",
        "name" : "projectsOverview",
        "meta" : {
            "show" : {"sidebar" : true, "group": "administration" },
            "label" : {"de" : "Projektübersicht", "en" : "Project overview"},
            "requiresAuthentication" : true,            
            "gridLayout" : [
                {
                    "x": 0,
                    "y": 0,
                    "w": 10,
                    "h": 6,
                    "minW" : 3,
                    "minH" : 2,
                    "i": "ownerBTSCockpit",
                    "static": false,
                    "module" : "owner",
                    "widget" : "TableCockpit",
                    
                    "customProperties": {
                        "noDetail" : true
                    },
                    "props" : {
                        "fullHeight" : true,
                        "neverOverflow" : false
                    }
                }],
            "allowAccess": function() {
                let userRoles = window?.globalVue?.$store.getters.getCurrentUserRoles ?? []
                return (userRoles.includes('BTS_USER') || userRoles.includes('ATERA_USER') || userRoles.includes('ADMINISTRATION_USER')) ?? false

            }
        }
    },
    {
        "path" : "\/customers\/:id",
        "name" : "customerSingle",
        "meta" : {
            "show" : {"sidebar" : false, "group": "cfg" },
            "label" : {"de" : "Kunde", "en" : "Customer"},
            "requiresAuthentication" : true,              
            "gridLayout" : [
                {
                    "x": 0,
                    "y": 0,
                    "w": 10,
                    "h": 6,
                    "minW" : 3,
                    "minH" : 2,
                    "i": "prodCustomerSingle",
                    "static": false,
                    "module" : "production",
                    "widget" : "SingleCustomer",
                    
                    "customProperties": {
                        "noDetail" : true
                    },
                    "props" : {
                        "fullHeight" : true,
                        "neverOverflow" : false
                    }
                }]
        }
    },{
        "path" : "\/customers",
        "name" : "customerTable",
        "meta" : {
            "show" : {"sidebar" : true, "group": "cfg" },
            "label" : {"de" : "Kundenliste", "en" : "Customer list"},
            "requiresAuthentication" : true,       
            "gridLayout" : [
                {
                    "x": 0,
                    "y": 0,
                    "w": 10,
                    "h": 6,
                    "minW" : 3,
                    "minH" : 2,
                    "i": "prodcustomerT",
                    "static": false,
                    "module" : "production",
                    "widget" : "TableCustomer",
                    
                    "customProperties": {
                        "noDetail" : true
                    },
                    "props" : {
                        "fullHeight" : true,
                        "neverOverflow" : false
                    }
                }]
        }
    },
    {
        "path" : "\/suppliers\/:id",
        "name" : "supplierSingle",
        "meta" : {
            "show" : {"sidebar" : false, "group": "cfg" },
            "label" : {"de" : "Lieferant", "en" : "Supplier"},
            "requiresAuthentication" : true,              
            "gridLayout" : [
                {
                    "x": 0,
                    "y": 0,
                    "w": 10,
                    "h": 6,
                    "minW" : 3,
                    "minH" : 2,
                    "i": "prodSupplierSingle",
                    "static": false,
                    "module" : "production",
                    "widget" : "SingleSupplier",
                    
                    "customProperties": {
                        "noDetail" : true
                    },
                    "props" : {
                        "fullHeight" : true,
                        "neverOverflow" : false
                    }
                }]
        }
    },{
        "path" : "\/suppliers",
        "name" : "supplierTable",
        "meta" : {
            "show" : {"sidebar" : true, "group": "cfg" },
            "label" : {"de" : "Lieferantenliste", "en" : "Supplier list"},
            "requiresAuthentication" : true,       
            "gridLayout" : [
                {
                    "x": 0,
                    "y": 0,
                    "w": 10,
                    "h": 6,
                    "minW" : 3,
                    "minH" : 2,
                    "i": "prodsupplierT",
                    "static": false,
                    "module" : "production",
                    "widget" : "TableSupplier",
                    
                    "customProperties": {
                        "noDetail" : true
                    },
                    "props" : {
                        "fullHeight" : true,
                        "neverOverflow" : false
                    }
                }]
        }
    },
    {
        "path" : "\/sapImportMask",
        "name" : "sapImportMask",
        "meta" : {
            "show" : {"sidebar" : true, "group": "cfg" },
            "label" : {"de" : "SAP Import", "en" : "SAP Import"},
            "requiresAuthentication" : true,       
            "gridLayout" : [
                {
                    "x": 0,
                    "y": 0,
                    "w": 10,
                    "h": 6,
                    "minW" : 3,
                    "minH" : 2,
                    "i": "sapImportM",
                    "static": false,
                    "module" : "production",
                    "widget" : "SAPUploadMask",
                    
                    "customProperties": {
                    },
                    "props" : {
                        "fullHeight" : true,
                        "neverOverflow" : false
                    }
                }],
                "allowAccess": function() {
                    return window?.globalVue?.$store.getters.getCurrentUserRoles?.includes('BTS_ACCESS_FINANCE') ?? false
    
                }
        }
    }
]