export default [
    {
        "path" : "\/orders\/:id",
        "name" : "orderSingle",
        "meta" : {
            "show" : {"sidebar" : false, "group": "administration" },
            "label" : {"de" : "Auftragsvorgang", "en" : "Order procedure"},
            "requiresAuthentication" : true,              
            "gridLayout" : [
                {
                    "x": 0,
                    "y": 0,
                    "w": 10,
                    "h": 6,
                    "minW" : 3,
                    "minH" : 2,
                    "i": "prodProjectSingle",
                    "static": false,
                    "module" : "production",
                    "widget" : "SingleProject",
                    
                    "customProperties": {
                        "noDetail" : true
                    },
                    "props" : {
                        "fullHeight" : true,
                        "neverOverflow" : false
                    }
                }]
        }
    },{
        "path" : "\/orders",
        "name" : "orderTable",
        "meta" : {
            "show" : {"sidebar" : true, "group": "administration" },
            "label" : {"de" : "Auftragsvorgänge", "en" : "Order procedures"},
            "requiresAuthentication" : true,       
            "gridLayout" : [
                {
                    "x": 0,
                    "y": 0,
                    "w": 10,
                    "h": 6,
                    "minW" : 3,
                    "minH" : 2,
                    "i": "prodprojectT",
                    "static": false,
                    "module" : "production",
                    "widget" : "TableProject",
                    
                    "customProperties": {
                        "noDetail" : true
                    },
                    "props" : {
                        "fullHeight" : true,
                        "neverOverflow" : false
                    }
                }]
        }
    }
]