<template>
 <div
  ref="ddnew"
 >
  <b-button 
    variant="transparent" 
    class="p-1"
    style="margin-left: -8px;"
    @click="handleClick"
    size="sm"
    >
    <b-icon :icon="(!!filterStatus || groupPosition > 0 || sortPosition > 0) ? 'caret-down-fill' :'caret-down'">
     <span class="sr-only">Filter</span>
    </b-icon>
  </b-button>

  <portal to="dropdowns" >
    <ul 
      class="bg-white p-2 border-secondary rounded position-fixed shadow-sm dropdown-menu show"
      :class="{
          'd-none' : !showDropdown

      }"
      @click="$event.stopPropagation()"
      :style="computedStyle"
      :id="$id('dropdown')"
      >
  <b-button
        block
        squared
        variant="light"
        @click="$emit('toggleSticky')"
        class="d-flex flex-nowrap mb-0 justify-content-start align-items-center"
    >
    <b-icon :icon="column['@sticky'] ? 'pin-fill' : 'pin'" /> 
    <div class="small flex-grow-1 text-left pl-1">
          Pin
      </div>
  </b-button>
  
  <template 
      v-if="!column.filter || column.filter.sort != false"  >
      <b-dropdown-divider></b-dropdown-divider>
      
  <b-dropdown-form form-class="p-0" @submit.prevent.stop >

    <!-- sorting component -->
    <base-cell-selector 
      @setSortData="$emit('setSortData' , $event)"
      @sortOff="$emit('sortOff')"
      :sortPosition="sortPosition"
      :sortData="sortData"
      :sortTag="sortTag"
      :rowData="rowData"
      :filteredRowData="filteredRowData"
      mode="Sort"
      :config="column"
    >
    </base-cell-selector>
  </b-dropdown-form>
  </template>

  <template 
      v-if="!column.filter || column.filter.group != false"  >
  <b-dropdown-divider></b-dropdown-divider>

  <b-dropdown-form form-class="p-0" @submit.prevent.stop >
    <!-- grouping component -->
    <base-cell-selector 
      :rowData="rowData"
      :filteredRowData="filteredRowData"
      :groupPosition="groupPosition"
      @setGroupData="$emit('setGroupData' , $event)"
      @groupOff="$emit('groupOff')"
      :groupData="groupData"
      :groupTag="groupTag"
      mode="Group"
      :config="column"
    >
    </base-cell-selector>
  </b-dropdown-form>
  </template>

  <template 
      v-if="!column.filter || column.filter.filter != false"  >
  <b-dropdown-divider></b-dropdown-divider>
  <b-dropdown-form form-class="p-0" @submit.prevent.stop >
    <!-- filter component -->
    <base-cell-selector 
      @setFilterValue="$emit('setFilterValue',$event)"
      @setFilterStatus="$emit('setFilterStatus',$event)"
      :filterValue="filterValue"
      :filterTag="filterTag"
      :filterStatus="filterStatus"
      mode="Filter"
      :config="column"
      :rowData="rowData"
      :filteredRowData="filteredRowData"
    >
    </base-cell-selector>    
  </b-dropdown-form>
  </template>
</ul>
  </portal>
</div>
</template>

<script>

export default {
  name: "BaseTableFilter",
  props: {
    filterStatus: null,
    filterValue: null,
    filterTag: null,
    groupPosition: null,
    groupData: null,
    groupTag: null,
    sortPosition: null,
    sortData : null,
    sortTag : null,
    column: null,
    rowData: null,
    filteredRowData: null,

  },
  data() {
        return {
            showDropdown: false,
            lockedDropdown: false,
            showDropdown: false,
            positionData: {
                xInput: 0,
                yInput: 0,
                wInput: 0,
                hInput: 0,
                wCalendar: 0,
                hCalendar: 0
            }
        }
    },
    computed: {
        locale: function(vm) {
            return ["de","en-gb"]
        },
        dateValid: function(vm) {
            return vm.momentDate && vm.momentDate.isValid && vm.momentDate.isValid()
        },
        computedStyle: function(vm) {
            let styleString = "z-index: 5000;"
            //place the calendar below the button aligned right
            let bottomEdge = vm.positionData.yInput + vm.positionData.hInput
            let leftEdge = vm.positionData.xInput - vm.positionData.wCalendar + vm.positionData.wInput

            if((bottomEdge + vm.positionData.hCalendar) > window.innerHeight ) {
                //flip position to be above the input field
                bottomEdge = vm.positionData.yInput - vm.positionData.hCalendar - 18
            }

            if(leftEdge < 0 ) {
                leftEdge = vm.positionData.xInput;
            }

            styleString += "left: " + (leftEdge + 6) + "px;"
            styleString += "top: " + (bottomEdge) + "px;"

            //window.innerHeight
            //window.innerWidth


            return styleString
        },
    },
    methods: {
        blurOnBackground: function(ev) {
          this.showDropdown = false
          window.removeEventListener('click', this.blurOnBackground)
        },
        handleClick: function(ev) {
            this.showDropdown = !this.showDropdown
            this.$nextTick(() => 
                this.$nextTick(() => 
                    this.$nextTick(() => this.recomputePositionData())

                )
            )
            window.addEventListener('click', this.blurOnBackground)
            ev.stopPropagation();
            
            window.addEventListener('scroll', function() { 
                this.recomputePositionData.bind(this) 
            }.bind(this))

        },
        recomputePositionData: function() {
            let dropdown = document.getElementById(this.$id('dropdown'))
            let dropdownBox = dropdown?.getBoundingClientRect()
            let buttonPosition = this.$refs.ddnew?.getBoundingClientRect();

            this.positionData.hInput = buttonPosition.height
            this.positionData.wInput = buttonPosition.width
            this.positionData.xInput = buttonPosition.x
            this.positionData.yInput = buttonPosition.y
            this.positionData.wCalendar = dropdownBox?.width ?? 0
            this.positionData.hCalendar = dropdownBox?.height ?? 0

        }, 
        toggleShow: function(hovered) {
            this.showDropdown = hovered 
        },
        lockDropdown: function(mode) {
            this.lockedDropdown = mode

            if(mode) {
                this.$refs.dd.show(true) 
                this.$emit('focus',mode)
            } else {
                this.$emit('blur',mode)
            }
        },
        updateValue: function() {
            if(this.momentDate === null) {
                this.$emit('input',null);
            } else {
                this.$emit('input',this.momentDate && this.momentDate?.isValid() && this.momentDate.format('YYYY-MM-DD') || null)
            }
            this.showDropdown = false
        }
    }
}

</script>
